<template>
  <v-container fluid>
    <v-form
      class="mb-6"
      style="width: 100%"
      v-model="valid"
      ref="form"
      v-if="license"
    >
      <v-card>
        <div class="pa-2">
          <div class="d-flex flex-row">
            <div style="width: 50%" class="mx-2">
              <GsInfiniteDropdown
                :config="licenseTemplateSelectionConfig"
                v-model="license.licenseTemplateId"
                :rules="formRules.required"
                class="mb-4"
              />
              <GsDatePicker
                :label="$t('validFrom')"
                v-model="license.validFrom"
                :rules="formRules.required"
              />
            </div>
          </div>

          <!-- errors -->
          <div class="d-flex flex-row" v-if="error">
            <span class="subtitle-1 mb-4 red--text"
              >{{ $t("form_invalid_text") }}: {{ error }}</span
            >
          </div>

          <!-- <actions> -->
          <div class="d-flex flex-row">
            <v-spacer />
            <v-btn class="ma-2" color="default" @click="cancel_click">
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="ma-2"
              color="primary"
              @click="save_click"
              :loading="loading"
            >
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("save") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { GsInfiniteDropdown } from "ngt-frontend-core";
import GsDatePicker from "@/components/GsDatePicker";
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "License",
  components: { GsInfiniteDropdown, GsDatePicker },
  data() {
    return {
      license: null,
      formRules: {
        required: [
          value =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true
        ],
        requiredArray: [
          value =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true
        ],
        optional: [value => true],
        optionalMin8: [value => (value ? value.length >= 8 : true)]
      },
      valid: true,
      error: null,
      loading: false
    };
  },
  computed: {
    licenseTemplateSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/license-templates",
          sort: "name:asc",
          fields: "name,licenseTemplateId",
          search: "name:ic:",
          dataMap: {
            title: "name",
            value: "licenseTemplateId"
          }
        },
        label: this.$t("licenseTemplate"),
        limit: 50,
        page: 0,
        rules: this.formRules.required,
        value: this.license?.licenseTemplateId
      };
    }
  },
  watch: {
    "$route.params.customerId": {
      immediate: true,
      handler(v) {
        if (v) {
          this.createLicense(v);
          return;
        }
      }
    }
  },
  methods: {
    createLicense(customerId) {
      this.license = {
        licenseTemplateId: null,
        validFrom: null,
        ownedBy: decodeURIComponent(customerId)
      };
    },
    async cancel_click() {
      this.$router.go(-1);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/licenses`;
      const method = "POST";

      const fields = ["licenseTemplateId", "validFrom", "ownedBy"];

      let data = {};
      for (let field of fields) {
        if (this.license[field]) {
          data[field] = this.license[field];
        }
      }

      if (Object.keys(data).length === 0) {
        this.$router.go(-1);
        return;
      }

      try {
        this.loading = true;
        const response = await callBffAPI({ url, method, data });
        if (response.status == 204 || response.status == 200) {
          this.$router.go(-1);
        }
      } catch (err) {
        const data = err?.response?.data;
        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
